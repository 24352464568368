import eventHub from "@/eventhub";

export default {
  methods: {
    showLoader() {
      eventHub.$emit("show_loader");
    },
    hideLoader() {
      eventHub.$emit("hide_loader");
    },
    async executeWithLoader(process) {
      try {
        this.showLoader();
        await process();
      } catch (error) {
        this.showError();
      } finally {
        this.hideLoader();
      }
    },
  },
};
