const API_URL = process.env.VUE_APP_API_URL
const BASE_URL = API_URL + 'auth'

export const AUTHENTICATE_URL = `${API_URL}authenticate`
export const REGISTER_URL = `${API_URL}register`
export const RESET_PASSWORD_URL = `${API_URL}password/reset`
export const CHANGE_PASSWORD_URL = `${API_URL}password/change`
export const COUNTRIES_URL = `${API_URL}addresses/countries`
export const DEPARTMENTS_URL = `${API_URL}addresses/departments`
export const CITIES_URL = `${API_URL}addresses/cities`

export const IDENTIFICATION_TYPES_URL = `${BASE_URL}/enums/identification-types`
export const CATEGORY_TYPES_URL = `${BASE_URL}/enums/category-types`
export const LINK_TYPES_URL = `${BASE_URL}/enums/link-types`
export const STATUSES_URL = `${BASE_URL}/enums/statuses`

export const AWARD_PROPOSALS_URL = `${BASE_URL}/award-proposals`
export const PDF_URL = `${BASE_URL}/award-proposals/pdfs`
export const CHANGE_STATUS_URL = `${BASE_URL}/award-proposals/change-status`
export const EXCEL_URL = `${BASE_URL}/award-proposals/proposal/export`

export const TERMS_AND_CONDITIONS_URL = process.env.VUE_APP_TERMS_AND_CONDITIONS_URL;