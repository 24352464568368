<template>
  <div>
    <p class="welcome__description">
      Si olvidó su contraseña, se le enviará un correo electrónico con un enlace que lo llevará a una página donde podrá crear una nueva contraseña.
    </p>
    <ValidationObserver ref="form">
      <ValidationProvider
        name="Email"
        vid="email"
        v-slot="{ errors }"
        rules="required|email"
      >
        <v-text-field
          v-if="!emailSent"
          v-model="model.username"
          outlined
          dense
          :error-messages="errors"
          label="Email *"
          required
        ></v-text-field>
      </ValidationProvider>
    </ValidationObserver>
    <div class="text-center">
      <v-btn
        block
        v-if="!emailSent"
        @click="validate"
        class="elevation-0 text-none mb-3"
        color="primary"
        dark
      >Restablecer</v-btn>

      <v-alert v-if="emailSent" color="green" text type="success">
        Hemos enviando un enlace a tu correo electronico para restablecer tu
        contraseña
      </v-alert>

      <v-btn
        block
        to="/"
        class="elevation-0 text--primary text-none"
        color="secondary"
        dark
      >Iniciar sesión</v-btn>
    </div>
  </div>
</template>

<script>
import { RESET_PASSWORD_URL } from "@/constants/ServicesConstants";
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from '@/mixins/notificationMixin';

export default {
  name: "Reset",
  components: {},
  mixins: [loaderMixin,notificationMixin],
  data: () => ({
    resetUrl: RESET_PASSWORD_URL,
    model: {
      username: "",
    },
    emailSent: false,
  }),
  methods: {
    async validate() {
      const result = await this.$refs.form.validate();
      if (result) {
        this.showLoader();
        this.$axios
          .post(this.resetUrl, this.model)
          .then(() => {
            this.emailSent = true;
            this.hideLoader();
          })
          .catch((error) => {
            this.hideLoader();
            if (error && error.response.status == 422) {
              this.invalidFields();
              this.$refs.form.setErrors(error.response.data);
            } else {
              this.showError();
            }
          });
      }
    },
  },
};
</script>
